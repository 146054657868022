.loading-home {
    background: #29292d;
    _background: linear-gradient(90deg,#013971 0,#055eb2 53%,#013a73);;
    opacity: 0.95;
    color: white;
    position: absolute;
    height: 100%;
    width: 100%;
    text-align: center;
  }
  .match-detail-loading{
    width: 2rem !important;
    height: 2rem !important;
    position: relative;
    left: 50%;
}

.match-detail-loading-main {
    position: absolute;
    top:35%;
    left:30%;    
    right: 30%;
}

.main-spinner{
    width: 2rem !important;
    height: 2rem !important;
}
.loading-message{
    font-size: 24px;
    margin-top: 20px;
    font-weight: 700;
    color: #ffdf1b;

}

.loading-home-tryagain, .loading-home-tryagain:focus {
    background: #F44336;
    border: #F44336;
    padding: 7px !important;
    color: white;
    border-radius: 2px;
    font-weight: bolder;
    text-transform: uppercase;

}